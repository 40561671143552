<template>
  <section title="">
    <b-row>
      <b-col md="4">
        <b-card>
          Total do Mês {{ total }}
        </b-card>
      </b-col>
      <b-col md="4" />
      <b-col md="4">
        <b-button
          variant="outline-success"
          class="mb-75 mt-2"
          fixed
          block
          @click="$router.push({name: 'app-comandas-add'})"
        > EMITIR NOVA NOTA
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-table
            responsive
            :items="items"
            :fields="fields"
          />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

export default {
  data() {
    return {
      // --------------------------------------------\\
      // ------------------- TABELA -----------------\\
      // --------------------------------------------\\
      url: 'painel/comandas',
      fields: ['data', 'cerveja', 'destilado', 'energetico',
        'refrigerante', 'suco', 'porcao', 'cigarro', 'comandas'],
      items: [],
      total: 0.00,
    }
  },
  created() {
    this.$http.get(this.url, { params: this.tableProps })
      .then(resp => {
        this.items = resp.data.prod
        this.total = resp.data.soma
      })
  },
}
</script>
<style lang="scss">
.table th {
  padding: 2px;
}

.table td {
  padding: 2px;
}
</style>
